import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Collapsible, Container } from '@nike/epic-react-ui'
import BreadCrumbs from '../../Breadcrumbs'

const breadCrumbs = [
  { link: '/workflow', label: 'Workflow' },
  { link: '/workflow/devops', label: 'DevOps' },
  [
    { link: '/workflow/devops/terraform', label: 'Terraform' },
    { link: '/workflow/devops/environments', label: 'Environments' },
    { link: '/workflow/devops/deploy', label: 'Deployments' }
  ]
]

const cloudfrontTf = `

resource "aws_cloudfront_origin_access_identity" "website_origin_access_identity" {
  comment = "site \${terraform.workspace} Access Identity"
}


resource "aws_cloudfront_distribution" "website_cdn" {
  enabled         = true
  is_ipv6_enabled = true
  price_class     = "PriceClass_100"

  # http_version = "http2"

  origin {
    origin_id = "origin-bucket-\${aws_s3_bucket.site.id}"

    # domain_name = "\${aws_s3_bucket.site.website_endpoint}"
    domain_name = aws_s3_bucket.site.bucket_regional_domain_name

    s3_origin_config {
      origin_access_identity = aws_cloudfront_origin_access_identity.website_origin_access_identity.cloudfront_access_identity_path
    }
  }
  default_root_object = "index.html"
  custom_error_response {
    error_code = "404"

    # error_caching_min_ttl = "360"
    response_code      = "200"
    response_page_path = "/index.html"
  }
  custom_error_response {
    error_code = "403"

    # error_caching_min_ttl = "360"
    response_code      = "200"
    response_page_path = "/index.html"
  }
  default_cache_behavior {
    allowed_methods = ["GET", "HEAD", "DELETE", "OPTIONS", "PATCH", "POST", "PUT"]
    cached_methods  = ["GET", "HEAD"]

    forwarded_values {
      query_string = "true"

      cookies {
        forward = "none"
      }
    }

    min_ttl          = "0"
    default_ttl      = "300"  //3600
    max_ttl          = "1200" //86400
    target_origin_id = "origin-bucket-\${aws_s3_bucket.site.id}"

    // This redirects any HTTP request to HTTPS. Security first!
    viewer_protocol_policy = "redirect-to-https"
    compress               = true
  }
  ordered_cache_behavior {
    allowed_methods = ["GET", "HEAD", "DELETE", "OPTIONS", "PATCH", "POST", "PUT"]
    cached_methods  = ["GET", "HEAD"]

    forwarded_values {
      query_string = "true"

      cookies {
        forward = "none"
      }
    }

    min_ttl          = "0"
    default_ttl      = "0"
    max_ttl          = "0"
    target_origin_id = "origin-bucket-\${aws_s3_bucket.site.id}"
    path_pattern     = "/index.html"

    // This redirects any HTTP request to HTTPS. Security first!
    viewer_protocol_policy = "redirect-to-https"
    compress               = true
  }
  restrictions {
    geo_restriction {
      restriction_type = "none"
    }
  }
  viewer_certificate {
    acm_certificate_arn      = module.cert.arn
    ssl_support_method       = "sni-only"
    minimum_protocol_version = "TLSv1"
  }
  aliases = [local.domain_name]
  lifecycle {
    ignore_changes = [tags]
  }
}`

const dnsTf = `
data "aws_route53_zone" "base" {
  name         = "\${var.hosted_zone_name}."
  private_zone = false
}

provider "aws" {
  region = "us-east-1"
  alias  = "certificates"
}

provider "aws" {
  region = var.region
  alias  = "dns"
}

module "cert" {
  source = "github.com/azavea/terraform-aws-acm-certificate?ref=3.0.0"

  providers = {
    aws.acm_account     = aws.certificates
    aws.route53_account = aws.dns
  }

  domain_name                       = local.domain_name
  hosted_zone_id                    = data.aws_route53_zone.base.zone_id
  validation_record_ttl             = "60"
  allow_validation_record_overwrite = true
}

resource "aws_route53_record" "domain" {
  name    = local.domain_name
  zone_id = data.aws_route53_zone.base.zone_id
  type    = "A"
  alias {
    name                   = aws_cloudfront_distribution.website_cdn.domain_name
    zone_id                = aws_cloudfront_distribution.website_cdn.hosted_zone_id
    evaluate_target_health = true
  }
}
`

const mainTf = `
terraform {
  backend "s3" {
    key    = "<APPNAME>-client"
    region = "us-west-2"
  }
  required_version = ">= 0.13.5"
  required_providers {
    aws = {
      source  = "hashicorp/aws"
      version = "~>3.4"
    }
  }
}

provider "aws" {
  region = var.region
}

data "aws_caller_identity" "current" {}
`

const s3SiteTf = `
data "aws_iam_policy_document" "website_s3_policy" {
  statement {
    sid       = "bucket_policy_site_main"
    actions   = ["s3:GetObject"]
    effect    = "Allow"
    resources = ["arn:aws:s3:::\${local.bucket_name}/*"]

    principals {
      type        = "AWS"
      identifiers = [aws_cloudfront_origin_access_identity.website_origin_access_identity.iam_arn]
      # identifiers = ["*"]
    }
  }
}

resource "aws_s3_bucket" "site" {
  bucket = local.bucket_name
  acl    = "private"
  policy = data.aws_iam_policy_document.website_s3_policy.json

  website {
    index_document = "index.html"
    error_document = "404.html"
  }

  tags = {}

  force_destroy = true
}
`

const variablesTf = `
#-------------------------------------------
# Required variables (do not add defaults here!)
#-------------------------------------------
variable domain_name {}
variable hosted_zone_name {}
variable env_tag {}

#-------------------------------------------
# Configurable variables
#-------------------------------------------
variable "region" {
  default = "us-west-2"
}

variable "app_namespace" {
  default = "<APPNAME>"
}

#-------------------------------------------
# Interpolated Values
#-------------------------------------------
locals {
  bucket_name = "{replace(var.app_namespace, "_", "-")}-\${terraform.workspace}-client"
  domain_name = "\${var.domain_name}.\${var.hosted_zone_name}"
}
`

const devTfvars = `
domain_name      = "<APPNAME>-dev"
hosted_zone_name = "cx-platform-test.nikecloud.com"
env_tag          = "Development"
`

const preprodTfvars = `
domain_name      = "<APPNAME>-preprod"
hosted_zone_name = "cx-platform-test.nikecloud.com"
env_tag          = "Development"
`

const prodTfvars = `
domain_name      = "<APPNAME>"
hosted_zone_name = "cx-platform-prod.nikecloud.com"
env_tag          = "Production"
`

function Terraform () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>Terraform</h2>
      <Card title='TL;DR' hover height={150}>
        Configure the Terraform files
        <pre>pipeline/deploy.sh</pre>
      </Card>
      <p>Terraform is used to create the <Link to='/workflow/devops/environments'>deployment environments</Link> on AWS</p>
      The GitHub repo must contain a folder named 'pipeline/terraform'
      with the following files:
      <Collapsible title='cloudfront.tf'>
        <pre>{ cloudfrontTf }</pre>
      </Collapsible>
      <Collapsible title='dns.tf'>
        <pre>{ dnsTf }</pre>
      </Collapsible>
      <Collapsible title='main.tf' subtitle='must be configured'>
        <p>Edit the <code>key</code> field</p>
        <pre>{ mainTf }</pre>
      </Collapsible>
      <Collapsible title='s3-site.tf'>
        <pre>{ s3SiteTf }</pre>
      </Collapsible>
      <Collapsible title='variables.tf' subtitle='must be configured'>
        <p>Edit the <code>default</code> field</p>
        <pre>{ variablesTf }</pre>
      </Collapsible>
      <hr/>
      <p>
        The repo must also contain a folder named 'pipeline/terraform/env_configs'
        with the following files:
      </p>
      <Collapsible title='dev.tfvars' subtitle='must be configured'>
        <pre>{ devTfvars }</pre>
      </Collapsible>
      <Collapsible title='preprod.tfvars' subtitle='must be configured'>
        <pre>{ preprodTfvars }</pre>
      </Collapsible>
      <Collapsible title='prod.tfvars' subtitle='must be configured'>
        <pre>{ prodTfvars }</pre>
      </Collapsible>
    </Container>
  )
}

export default Terraform

import React from 'react'

import { Card, Container, Collapsible } from '@nike/epic-react-ui'
import BreadCrumbs from '../../Breadcrumbs'

const breadCrumbs = [
  { link: '/workflow', label: 'Workflow' },
  { link: '/workflow/devops', label: 'DevOps' },
  [
    { link: '/workflow/devops/deploy', label: 'Deployments' },
    { link: '/workflow/devops/environments', label: 'Environments' },
    { link: '/workflow/devops/terraform', label: 'Terraform' }
  ]
]

const oktaAwsLoginConfig = `
[cx-test]
okta_org_url = https://nike.okta.com
okta_auth_server = aus27z7p76as9Dz0H1t7
client_id = <CLIENT_ID>
gimme_creds_server = https://api.sec.nikecloud.com/gimmecreds/accounts
aws_appname = AWS WaffleIron Multi-Account
aws_rolename = arn:aws:iam::601323899993:role/NIKE.SSO.AdminRole
write_aws_creds = True
cred_profile = ngp
okta_username = <YOUR_EMAIL_HERE>@nike.com
app_url =
resolve_aws_alias = False
preferred_mfa_type = push
aws_default_duration = 3600
device_token = <YOUR_UNIQUE_DEVICE_TOKEN>
`

const clientDeploy = `
npm i
npm run build

# S3 bucket name
CLIENT_BUCKET_NAME="<APPNAME>-\${WORKSPACE}-client"

# Push client code to s3
aws s3 rm s3://$CLIENT_BUCKET_NAME/  --recursive
aws s3 sync build/ s3://$CLIENT_BUCKET_NAME/ --exclude \\"*.DS_Store*\\"
aws s3 cp build/index.html s3://$CLIENT_BUCKET_NAME/ --cache-control max-age=0
`

function Deploy () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>Deployments</h2>
      <div style={{ display: 'flex' }}>
        <Card title='TL;DR' subtitle='deploy to dev' hover height={150}>
          <pre>gimme-aws-creds --profile cx-test</pre>
          <pre>pipeline/client_deploy.sh dev</pre>
        </Card>
        <div style={{ width: 20 }}/>
        <Card title='TL;DR' subtitle='deploy to preprod' hover height={150}>
          <pre>GitHub Action push branch</pre>
        </Card>
        <div style={{ width: 20 }}/>
        <Card title='TL;DR' subtitle='deploy to prod' hover height={150}>
          <pre>GitHub Action PR merge to main</pre>
        </Card>
      </div>
      <hr/>
      <Collapsible title='AWS Credentials'>
        <div style={{ display: 'flex' }}>
          <div>
            <p>
              To deploy a React app to AWS CloudFront, a user needs AWS credentials.
              An admin has to authorize each user making deployments with
              this AWS WaffleIron account:<br/>
              <code>cx-platform-test (601323899993)</code>
            </p>
            <div>
              When authorized, a user runs this command in a terminal window:
              <pre>gimme-aws-creds</pre>
              enters a Multi-factor Authentication code and selects
              <pre>[0] AWS WaffleIron Multi-Account</pre>
              then picks this role:
              <pre>arn:aws:iam::601323899993:role/NIKE.SSO.AdminRole</pre>
            </div>
          </div>
          <div style={{ marginLeft: 10 }}>
            The command can be reduced to a one-line command with a parameter:
            <pre>gimme-aws-creds --profile cx-test</pre>
            by adding the following to your <code>~/.okta_aws_login_config</code> file:
            <pre>{ oktaAwsLoginConfig }</pre>
          </div>
        </div>
      </Collapsible>
      <hr/>
      <Collapsible title='Pipeline'>
        <div>
          The GitHub repo should have a folder named 'pipeline'
          with the following script:
          <ul>
            <li>client-deploy.sh - performs the React app build and deployment</li>
            <pre>{ clientDeploy }</pre>
          </ul>
        </div>
        <p>The CLIENT_BUCKET_NAME is defined in pipeline/terraform/variables.tf</p>
      </Collapsible>
      <hr/>
      <Collapsible title='GitHub Actions'>
        <p>
          GitHub Actions
        </p>
      </Collapsible>
    </Container>
  )
}

export default Deploy

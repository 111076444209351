import React from 'react'

import { Container } from '@nike/epic-react-ui'
import { Sustainability } from '@nike/nike-design-system-icons'

const h1Style = {
  verticalAlign: 'super'
}

function Home () {
  return (
    <Container>
      <h1><Sustainability size='l'/>
        <span style={h1Style}>Platform Capabilities</span>
      </h1>
      UI/UX developers for the Enterprise Platforms Console
    </Container>
  )
}

export default Home

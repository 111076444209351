import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from '@nike/epic-react-ui'
import BreadCrumbs from '../Breadcrumbs'

const breadCrumbs = [
  { link: '/workflow', label: 'Workflow' },
  { link: '/workflow/devops', label: 'DevOps' }
]

export default function DevOps () {
  return (
    <Container>
      <BreadCrumbs links={breadCrumbs}/>
      <h2>DevOps</h2>
      <div>
        Customer Experience uses AWS Cloudfront to serve up the React apps.
        <p>We use three deployment <Link to='/workflow/devops/environments'>environments</Link></p>
        <p>which are configured using <Link to='/workflow/devops/terraform'>terraform</Link></p>
        <p>and delivered using <Link to='/workflow/devops/deploy'>deployments</Link></p>
      </div>
    </Container>
  )
}

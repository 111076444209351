import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from '@nike/epic-react-ui'

function Workflow () {
  return (
    <Container>
      <h3>Workflows</h3>
      <p>CX</p>
      <ul>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=CX+Detailed+Intake+Form'>
              Intake
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://jira.nike.com/secure/RapidBoard.jspa?rapidView=12712'>
              Triage
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://jira.nike.com/secure/RapidBoard.jspa?rapidView=13145&projectKey=CX&view=planning.nodetail&epics=visible&issueLimit=100'>
              Backlog
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://jira.nike.com/secure/RapidBoard.jspa?rapidView=13145&projectKey=CX'>
              Jira Sprints
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://github.nike.com/cx/practices'>
              Engineering Standards and Practices
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://confluence.nike.com/display/NGP/Customer+Experience+Ceremonies'>
                  Agile Ceremonies
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://confluence.nike.com/display/NGP/CX+Team+Calendar'>
                  Team Calendar
          </a>
        </li>
      </ul>
      <p>UX</p>
      <ul>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://www.figma.com/files/845061392473249058/recent?fuid=900061026264684783'>
                  Design Standards
          </a>
        </li>
      </ul>
      <p>PlatCap</p>
      <ul>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=Platform+Capabilities+Working+Agreement'>
                  Squad Working Agreement
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://confluence.nike.com/pages/viewpage.action?spaceKey=NGP&title=PlatCap+Tech+Stack'>
                  Tech Stack
          </a>
        </li>
      </ul>
      <p>Resources</p>
      <ul>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://github.com/nike-cx'>
                  GitHub repositories
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://github.com/nike-cx/ui-template'>
                  UI Template
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://ep-styleguide.niketech.com/'>
                  EPIC React UI
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://tourguide.prod.commerce.nikecloud.com/welcome'>
                  Tour Guide
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://pages.github.nike.com/UXFoundation/nike-design-system-components/docs/storybook/?path=/story/intro-getting-started--page'>
                  Nike Design System Components
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://pages.github.nike.com/UXFoundation/nike-design-system-components/docs/storybook/?path=/story/icons-nike-design-system-icons--all-icons'>
                  Nike Design System Icons
          </a>
        </li>
      </ul>
      <p>DevOps</p>
      <ul>
        <li><Link to='/workflow/devops'>DevOps</Link></li>
        <li><Link to='/workflow/devops/environments'>Environments</Link></li>
        <li><Link to='/workflow/devops/deploy'>Deployments</Link></li>
        <li><Link to='/workflow/devops/terraform'>Terraform</Link></li>
      </ul>
    </Container>
  )
}

export default Workflow

import '@nike/epic-react-ui/dist/styles/main.css'

import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { ThemeProvider } from '@material-ui/core/styles'
import { themeNike } from './components/theme'

import Header from './components/Header'
import Home from './components/Home'

import Onboarding from './components/Onboarding'
import Nike from './components/Onboarding/Nike'
import TechStack from './components/Onboarding/TechStack'
import Tools from './components/Onboarding/Tools'

import Workflow from './components/Workflow'
import DevOps from './components/Workflow/DevOps'
import Deploy from './components/Workflow/DevOps/Deploy'
import Environments from './components/Workflow/DevOps/Environments'
import Terraform from './components/Workflow/DevOps/Terraform'

import Authorizations from './components/Authorizations'

import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
const oktaAuthConfig = { // PKCE authorization flow
  clientId: 'nike.niketech.cx-dev',
  issuer: 'https://nike-qa.oktapreview.com', // 'https://nike.okta.com',
  redirectUri: window.location.origin + '/login/callback',
  ignoreSignature: true
}
const oktaAuth = new OktaAuth(oktaAuthConfig)

function App () {
  return (
    <ThemeProvider theme={themeNike}>
      <BrowserRouter>
        <Security oktaAuth={oktaAuth}>
          <Header/>
          <Switch>
            <Route path='/login/callback' component={LoginCallback} />
            <SecureRoute exact path='/' component={Home} />
            <SecureRoute exact path='/onboarding' component={Onboarding} />
            <SecureRoute exact path='/onboarding/nike' component={Nike} />
            <SecureRoute exact path='/onboarding/techstack' component={TechStack} />
            <SecureRoute exact path='/onboarding/tools' component={Tools} />
            <SecureRoute exact path='/authorizations' component={Authorizations} />
            <SecureRoute exact path='/workflow' component={Workflow} />
            <SecureRoute exact path='/workflow/devops' component={DevOps} />
            <SecureRoute exact path='/workflow/devops/deploy' component={Deploy} />
            <SecureRoute exact path='/workflow/devops/environments' component={Environments} />
            <SecureRoute exact path='/workflow/devops/terraform' component={Terraform} />
          </Switch>
        </Security>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App

import React from 'react'

import { Container, Collapsible } from '@nike/epic-react-ui'

function Authorizations () {
  return (
    <Container>
      <h3>Authorizations</h3>
      <p>Current members of CX must add new users to the following services:</p>
      <Collapsible title='GitHub'>
        <ul>
          <li>github.com</li>
        </ul>
      </Collapsible>
      <Collapsible title='Okta'>
        <ul>
          <li>okta.com</li>
        </ul>
      </Collapsible>
      <Collapsible title='AWS'>
        <ul>
          <li>aws.nike.com</li>
        </ul>
      </Collapsible>
      <p>After being added to GitHub,</p>
      <ul>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://github.com/enterprises/nike/sso'>
            Single Sign-on (SSO)
          </a>
        </li>
        <li>
          <a target='_blank' rel='noopener noreferrer'
            href='https://docs.github.com/en/github/authenticating-to-github/adding-a-new-ssh-key-to-your-github-account'>
            Adding ssh key to github
          </a>
        </li>
      </ul>
    </Container>
  )
}

export default Authorizations

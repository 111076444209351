import React from 'react'
import { Link } from 'react-router-dom'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export default function BreadCrumbs ({ links }) {
  const breadcrumbs = []
  let c = 0
  for (const link of links) {
    if (c > 0) breadcrumbs.push(<span key={c++}> / </span>)
    if (!Array.isArray(link)) {
      breadcrumbs.push(<Link to={link.link} key={c++}>{link.label}</Link>)
      continue
    }
    breadcrumbs.push(<Select key={c++}
      defaultValue={link[0]?.label}
      style={{ paddingLeft: 6, fontSize: 12 }}
      children={ link.map((child, i) =>
        <MenuItem component={Link} to={child.link} key={i}
          style={{ fontSize: 12 }} value={child.label}>{ child.label }</MenuItem>
      )}
    />)
  }

  return (
    <div style={{ margin: '24px auto', fontSize: 12 }}>
      { breadcrumbs }
    </div>
  )
}

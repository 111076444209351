import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

const colors = {
  black: '#111111',
  gray1: '#757575',
  gray2: '#CCCCCC',
  gray3: '#E5E5E5',
  gray4: '#F5F5F5',
  gray5: '#FAFAFA',
  white: '#FFFFFF',
  blue: '#0070F5',
  error: '#D43F21',
  hover: '#2A72B2'
}

const themeNike = createMuiTheme({
  typography: {
    fontFamily: '"Helvetica Neue Pro", "Helvetica Neue", Helvetica',
    display1: { fontFamily: 'Nike Futura', fontSize: 62 },
    display2: { fontFamily: 'Nike Futura', fontSize: 56 },
    display3: { fontFamily: 'Nike Futura', fontSize: 48 },
    display4: { fontFamily: 'Nike Futura', fontSize: 40 },
    display5: { fontFamily: 'Nike Futura', fontSize: 24 },
    title1: { fontSize: 36, fontWeight: 500 },
    title2: { fontSize: 32, fontWeight: 500 },
    title3: { fontSize: 28, fontWeight: 500 },
    title4: { fontSize: 24, fontWeight: 500 },
    title5: { fontSize: 20, fontWeight: 500 },
    title6: { fontSize: 16, fontWeight: 500 },
    subtitle1: { fontSize: 16, fontWeight: 500, lineHeight: '24px' },
    subtitle2: { fontSize: 14, fontWeight: 500, lineHeight: '20px' },
    subtitle3: { fontSize: 14, lineHeight: '16px' },
    body1: { fontSize: 20, lineHeight: '28px' },
    body2: { fontSize: 16, lineHeight: '24px' },
    body3: { fontSize: 14, lineHeight: '20px' },
    legal1: { fontSize: 12, lineHeight: '18px' },
    label1: { fontSize: 10, lineHeight: '12px' },
    button1: { fontSize: 16, fontWeight: 500, lineHeight: '24px' },
    button2: { fontSize: 14, fontWeight: 500, lineHeight: '20px' },
    button3: { fontSize: 12, fontWeight: 500, lineHeight: '18px' }
  },
  palette: {
    primary: {
      main: colors.blue,
      dark: colors.hover
    }
  }
})

themeNike.overrides = {
  MuiAppBar: {
    colorPrimary: {
      color: colors.black,
      backgroundColor: colors.gray3
    }
  },
  MuiAutocomplete: {
    endAdornment: {
      top: '9px'
    }
  },
  MuiButton: {
    root: {
      borderRadius: '16px',
      fontFamily: '"Helvetica Neue", Helvetica',
      fontWeight: 400,
      textTransform: 'inherit'
    },
    sizeSmall: {
      borderRadius: '24px',
      fontSize: '10px'
    },
    sizeLarge: {
      borderRadius: '24px',
      fontSize: '16px'
    }
  },
  MuiCheckbox: {
    colorPrimary: colors.blue
  },
  MuiContainer: {
    root: {
      padding: '20px'
    }
  },
  MuiFormControlLabel: {
    label: { fontSize: '14px' }
  },
  MuiOutlinedInput: {
    input: {
      padding: '10px'
    }
  },
  MuiTableCell: {
    head: {
      borderRight: '1px solid #ccc',
      color: colors.blue,
      fontWeight: 'inherit'
    },
    stickyHeader: {
      backgroundColor: 'white',
      color: colors.blue
    }
  },
  MuiTooltip: {
    arrow: {
      color: colors.black,
      fontSize: 24
    },
    tooltipArrow: {
      backgroundColor: colors.black
    }
  }
}

export { colors, themeNike }
